import React from 'react'

export default function EducationTab() {
    return (
        <div className='row' id='educationTab' data-aos='fade-in' data-aos-easing='linear' data-aos-delay='0' data-aos-duration='500'>
            <div className='col-12 col-lg-6'>
                <div className='content'>
                    <span className='subtitle my-1 text-start'>2015 - 2022</span>
                    <h4 className='title my-0 text-start'>Education Profile</h4>
                    <div className='list'>
                        <div className='col-lg-12'>

                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Graduation</h4>
                                            <span>Uttarakhand Technical University (2018 - 2022)</span>
                                        </div>
                                        <div className='percentage'>
                                            <span>80%</span>
                                        </div>
                                    </div>
                                    <p className='card-text'>I completed my Bachelor of Technology (B.Tech) degree in Computer Science and Engineering (CSE) from Uttarakhand Technical University. The duration of the program was from 2018 to 2022. During my undergraduate studies, I gained a comprehensive understanding of various concepts and technologies related to computer science and engineering. The program equipped me with the necessary skills to analyze problems, design solutions, and develop software applications. It also provided me with a strong foundation in programming languages, algorithms, database management, and other relevant subjects.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Intermediate</h4>
                                            <span>Central Board Of Secondary Education (2017 - 2018)</span>
                                        </div>
                                        <div className='percentage'>
                                            <span>86%</span>
                                        </div>
                                    </div>
                                    <p className='card-text'>I pursued my intermediate education from the Central Board of Secondary Education (CBSE). I completed my 12th grade with a specialization in Physics, Chemistry, and Mathematics (PCM) during the academic year 2017-18. This stage of my education allowed me to delve deeper into the core subjects of science and mathematics. It helped me develop analytical thinking, problem-solving skills, and a solid understanding of the fundamental concepts in these fields.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Matriculate</h4>
                                            <span>Central Board Of Secondary Education (2017 - 2018)</span>
                                        </div>
                                        <div className='percentage'>
                                            <span>82%</span>
                                        </div>
                                    </div>
                                    <p className='card-text'>My secondary education was completed under the Central Board of Secondary Education (CBSE). I successfully completed my 10th grade during the academic year 2015-16. This phase of my education provided me with a well-rounded understanding of various subjects, including Mathematics, Science, Hindi, English, and Social Science. It laid the groundwork for my future academic pursuits and instilled in me a sense of discipline, time management, and effective study habits.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 col-lg-6 mt-5 mt-lg-0'>
                <div className='content'>
                    <span className='subtitle my-1 text-start'>2022 - 2023</span>
                    <h4 className='title my-0 text-start'>Certification Profile</h4>
                    <div className='list'>
                        <div className='col-lg-12'>

                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>React (Basic)</h4>
                                            <span>HackerRank (2023)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/8b1bdf37b3ea' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>React (Basic) It covers topics like Basic Routing, Rendering Elements,State Management (Internal Component State), Handling Events, ES6 and JavaScript and Form Validation.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>JavaScript (Basic)</h4>
                                            <span>HackerRank (2023)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/415a26e554ae' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>It covers topics like, Functions, Currying, Hoisting, Scope, Inheritance, Events and Error Handling.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>SQL (Basic)</h4>
                                            <span>HackerRank (2023)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/65875f20d71f' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>It includes simple queries, relationships, and aggregators.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Problem Solving (Basic)</h4>
                                            <span>HackerRank (2023)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/8fac6c142059' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>It covers basic topics of Data Structures (such as Arrays, Strings) and Algorithms (such as Sorting and Searching).</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Java (Basic)</h4>
                                            <span>HackerRank (2022)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/cd88cf7a2658' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>It will cover basic topics in Java language such as classes, data structures, inheritance, exception handling, etc.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>CSS</h4>
                                            <span>HackerRank (2022)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://www.hackerrank.com/certificates/891739f46378' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>It covers topics like exploring Cascading and Inheritance, exploring text styling fundamentals, understanding the use of layouts in CSS, understand the boxing of elements in CSS, among others.</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body p-0'>
                                    <div className='heading'>
                                        <div className='card-title'>
                                            <h4>Python Full Stack</h4>
                                            <span>Pentagon Space (2021 - 2022)</span>
                                        </div>
                                        <div className='view-certificate'>
                                            <span><a href='https://drive.google.com/file/d/1gqKp6kmaCctWaUEtnId5JC2FCfNO-YDs/view' target='_blank' rel='noreferrer'>View Certificate</a></span>
                                        </div>
                                    </div>
                                    <p className='card-text'>This certificate of completion is from batch PS28MAR22PFS#129 offered by Pentagon Space. The program covered the full stack development using Python.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
