import React from "react";
import projectsData from "../projectsData.json";
import ProjectCard from "./ProjectCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

export default function ProjectsTab() {
  const CustomPrevArrow = ({ onClick }) => (
    <button className="custom-prev-arrow" onClick={onClick}>
      <i>
        <FiArrowLeft />
      </i>
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button className="custom-next-arrow" onClick={onClick}>
      <i>
        <FiArrowRight />
      </i>
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots) => <ul id="slider-dots">{dots}</ul>,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div
      className="row"
      id="projectsTab"
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-delay="0"
      data-aos-duration="500"
    >
      <Slider {...settings}>
        {projectsData.map((project, index) => (
          <ProjectCard
            key={index}
            preview={project.preview}
            category={project.category}
            title={project.title}
            description={project.description}
            githubLink={project.githubLink}
            deployLink={project.deployLink}
          />
        ))}
      </Slider>
    </div>
  );
}
