import React from 'react';

export default function ExperienceTab() {
    return (
        <div className="row" id='experienceTab' data-aos="fade-in" data-aos-easing="linear" data-aos-delay="0" data-aos-duration="500">
            <div className="col-12 col-lg-6">
                <div className="content">
                    <span className="subtitle my-1 text-start">2023</span>
                    <h4 className="title my-0 text-start">Job Experience</h4>
                    <div className="list">
                        <div className="col-lg-12">

                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="heading">
                                        <div className="card-title">
                                            <h4>Software Test Engineer</h4>
                                            <span>Rareprob Solution Pvt. Ltd. (Jan 2023 - Present)</span>
                                        </div>
                                    </div>
                                    <div className="card-text">
                                        <ul>
                                            <li>Conducted comprehensive testing of Android applications, iOS applications, and websites developed by RareProb Solution Pvt Ltd.</li>
                                            <li>Collaborated with cross-functional teams, including developers and product managers, to identify and document software defects and ensure their timely resolution.</li>
                                            <li>Developed and executed test plans, test cases, and test scripts to ensure the quality and reliability of software products.</li>
                                            <li>Utilized a range of testing methodologies, including manual testing and automated testing tools, to perform functional, performance, and regression testing.</li>
                                            <li>Played a key role in bug tracking, issue reporting, and providing detailed test reports to stakeholders.</li>
                                            <li>Active participation in Agile development processes, including sprint planning, daily stand-ups, and retrospective meetings.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                <div className="content">
                    <span className="subtitle my-1 text-start">2022 - 2023</span>
                    <h4 className="title my-0 text-start">Clients Project</h4>
                    <div className="list">
                        <div className="col-lg-12">

                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="heading">
                                        <div className="card-title">
                                            <h4>Loan Management System</h4>
                                            <span>Chaudhary Enterprises (Mar 2023)</span>
                                        </div>
                                    </div>
                                    <div className="card-text">
                                        <ul>
                                            <li>Developed a client project called Loan Management System using PHP and MySQL.</li>
                                            <li>Implemented a secure login system with two roles: admin and staff.</li>
                                            <li>Admin login provides complete control over managing the system and its data.</li>
                                            <li>Staff login offers limited controls for managing borrowers and loan-related information.</li>
                                            <li>The system efficiently manages various data including borrower details, active loans, types of loans offered, file charges, expenditure data, pending payments, and total payments collected by staff on a daily basis.</li>
                                            <li>Designed specifically for Chaudhary Enterprises to streamline loan management processes.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="heading">
                                        <div className="card-title">
                                            <h4>Library Website</h4>
                                            <span>Day Dream Library (Sep 2022)</span>
                                        </div>
                                    </div>
                                    <p className="card-text">
                                        Day Dream Library is a website designed to provide information and showcase the features of a Day Dream Library. The website was created using simple HTML, CSS, and JavaScript technologies, focusing on the front-end development.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
