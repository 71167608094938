import React from "react";
import projectsData from "../projectsData.json";
import PortfolioCard from "./PortfolioCard";

export default function Portfolio() {
  return (
    <div
      className="container section-separator section-gap"
      id="portfolio"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="row">
        <span className="subtitle text-uppercase">
          Visit my portfolio and keep your feedback
        </span>
        <h2 className="title">My Portfolio</h2>
        <div className="row content mt-5 mx-auto">
          {projectsData.map((project, index) => (
            <PortfolioCard
              key={index}
              preview={project.preview}
              category={project.category}
              title={project.title}
              description={project.description}
              githubLink={project.githubLink}
              deployLink={project.deployLink}
              aosDelay={project.aosDelay}
            />
          ))}
        </div>
        {/* <div className="row content mt-5 mx-auto">
          <PortfolioCard portfolioImage={PortfolioMyWebsite} category="Development" portfolioTitle="My Website" portfolioDescription="I have created my own website that showcases detailed information about me. Using ReactJS, I have designed this platform to share my background, experiences, and interests. To enhance communication, I have used EmailJS on the contact page, enabling visitors to submit their details, which are then sent directly to my email for further interaction." gitHubLink="" portfolioLink="/" aosDelay="100" />

          <PortfolioCard portfolioImage={PortfolioAnyChat} category="Development" portfolioTitle="AnyChat" portfolioDescription="AnyChat, a real-time chat application developed using React.js, Node.js, Socket.IO, and Firebase. Implemented Firebase Realtime Database for seamless data synchronization and integrated Firebase Authentication for secure user access." gitHubLink="https://github.com/ImHappyKumar/any-chat/" portfolioLink="https://anychat-happykumar.web.app/" aosDelay="300" />

          <PortfolioCard portfolioImage={PortfolioQuickNotes} category="Development" portfolioTitle="QuickNotes" portfolioDescription="QuickNotes is a user-friendly website that allows users to save and organize their notes easily. Users can sign up or log in to access the app, enabling them to create, edit, and delete their notes conveniently, thus making note-taking a smooth process" gitHubLink="https://github.com/ImHappyKumar/quick-notes" portfolioLink="" aosDelay="500" />

          <PortfolioCard portfolioImage={PortfolioNewsWave} category="Development" portfolioTitle="NewsWave" portfolioDescription="The NewsWave web app is a ReactJS-based application that fetches data from NewsAPI to provide users with up-to-date news articles. It offers a user-friendly interface for browsing and staying informed about various topics." gitHubLink="https://github.com/ImHappyKumar/news-wave/" portfolioLink="" aosDelay="100" />

          <PortfolioCard portfolioImage={PortfolioTextAlter} category="Development" portfolioTitle="Text Alter" portfolioDescription="TextAlter is a versatile tool that provides various text manipulation features. It includes word counting, character counting, line counting, reading time estimation, and the ability to transform text by converting it to uppercase or lowercase, toggling case, alternating case, capitalizing text, removing extra spaces or lines, copying text, and clearing the text." gitHubLink="https://github.com/ImHappyKumar/text-alter/" portfolioLink="https://textalter-happykumar.web.app/" aosDelay="300" />

          <PortfolioCard portfolioImage={PortfolioPingPongGame} category="Development" portfolioTitle="Ping Pong Game" portfolioDescription="Ping pong, also known as table tennis, is a game that involves two or four players. The objective of the game is to hit a lightweight ball back and forth across a table using small solid rackets, commonly referred to as paddles." gitHubLink="https://github.com/ImHappyKumar/ping-pong-game/" portfolioLink="https://ImHappyKumar.github.io/ping-pong-game/" aosDelay="500" />
        </div> */}
      </div>
    </div>
  );
}
